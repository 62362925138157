import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Breadscrum from "../../components/Breadscrum";


const OpZavetiInner = styled.div`
  width: calc(100% - 50px);
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 1150px;
  margin-top: 25px;

  @media (min-width: 900px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  h1 {
    font-family: NewKansas;
    font-size: 26px;
    margin-bottom: 40px;
    color: #000;
    margin-top: 20px;

    @media (min-width: 900px) {
      font-size: 30px;
    }
  }

  h2 {
    font-family: Visuelt-Medium;
    font-size: 22px;
    margin-bottom: 25px;
    margin-top: 40px;
    color: #000000;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }
`;


const ObchodniPodminkyZaveti = ({ data }) => {

  return (
    <Layout
      customCtaText={"Zkusit ZDARMA"}
      customCtaLink={"https://zaveti.goodbye.cz/registrace"}
      customLoginText={"Přihlásit se"}
      customLoginLink={"https://zaveti.goodbye.cz/prihlaseni"}
      subMenuItems={[
        {
          name: "Závěti",
          url: "/zaveti/",
        },
        {
          name: "Digitální archiv",
          url: "/digitalni-archiv/",
        },
        {
          name: "Předplacený pohřeb",
          url: "/predplaceny-pohreb/",
        },
      ]}
    >
      <SEO
        title="Obchodní podmínky, Závěti"
        description="Obchodní podmínky jsou nedílnou součástí smlouvy o zprostředkování a odchylná ujednání ve smlouvě o zprostředkování mají přednost před ustanoveními obchodních podmínek."
        image="/zavetiOg.png"
      />

      <OpZavetiInner>
        <Breadscrum
          visible={true}
          stranky={[
            {name: "Domů", url: "/"},
            {name: "Závěti", url: "/zaveti/"},
            {name: "Obchodní podmínky", url: "/zaveti/obchodni-podminky/"}
          ]}
        />
        <h1>Obchodn&iacute; podm&iacute;nky společnosti Goodbye s.r.o.</h1>
        <div class="podminkyInner">
          <p>Jedná se o počítačový program s názvem Moje Závěť, dále jen <strong>Moje Závěť</strong>. Součástí je internetová stránka dostupná z Internetové adresy zaveti.goodbye.cz.</p>
          <p>Obchodní společnost Goodbye s.r.o., IČO: 08319588, se sídlem Na Kopci 164, 251 62 Svojetice, spisová značka C 316877, vedená u Městského soudu v Praze, dále jen <strong>Poskytovatel</strong>.</p>
          <p>Právnická nebo fyzická osoba, která užívá aplikaci Moje Závěť, dále jen <strong>Uživatel</strong>.</p>
          <p>Jakákoliv část aplikace Moje Závěť, která tvoří ucelenou a vzájemně propojenou část Aplikace, jenž se zobrazí Uživateli jako smlouva, dohoda či jinak označený právní dokument (resp. tvoří samostatný řádek v příslušné tabulce v databázi Aplikace), dále jen <strong>Dokument.</strong></p>
          <p><strong>Internetová adresa </strong>je jakákoliv internetová adresa, ze které je dostupná aplikace Moje Závěť, zejména zaveti.goodbye.cz</p>
          <p><strong>Vygenerování </strong>je funkce aplikace Moje Závěť, která převede Uživatelem vyplněnou a nastavenou podobu dokumentu do textové podoby s možností tisku do souboru formátu „PDF“ a/nebo v jiném formátu.</p>
          <p><strong>Užití aplikace Moje Závěť</strong></p>
          <p>Uživatel má právo užít aplikaci Moje Závěť k Vygenerování Dokumentu za cenu uvedenou u tohoto Dokumentu.</p>
          <p>Za Vygenerování se neplatí žádné skryté poplatky. Cena uvedená u Dokumentu je konečná.</p>
          <p>Po vyplnění všech povinných částí formuláře na tvorbu Dokumentu Uživatelem, Uživatel obdrží přístup ke konečné verzi Dokumentu na jím vytvořený zákaznický účet, a to automaticky po zaplacení přes platební bránu.</p>
          <p>Prohlašujete, že užitím služeb aplikace Moje Závěť jste dovršili věku minimálně 18 let a jste způsobilí nabývat pro sebe vlastním právním jednáním práva a zavazovat se k povinnostem (právně jednat) a že jste nebyli v této způsobilosti nijak soudně omezeni.</p>
          <p><strong>Moje Závěť není právní služba</strong></p>
          <p>Uživatel bere na vědomí, že aplikace Moje Závěť není právní službou ani právní službu nenahrazuje.</p>
          <p>Poskytovatel nemůže ovlivnit kombinaci Dokumentu a/nebo nastavení Dokumentu a/nebo údaje do Dokumentu, jenž Uživatel zvolí nebo zadá.</p>
          <p>Uživatel bere na vědomí, že aplikace Moje Závěť je pouze (zejména textovým) editorem umožňujícím tvorbu právních dokumentů a databází řadící její prvky (texty) do celků a zobrazuje je Uživateli.</p>
          <p>Uživatel se zavazuje jednat tak, aby mu užíváním aplikace Moje Závěť nevznikla žádná škoda. Současně přijímá výhradní plnou odpovědnost za jím učiněná nastavení Dokumentů při užívání aplikace Moje Závěť.</p>
          <p>Uživatel činí nesporným, že vzhledem k charakteru aplikace Moje Závěť, Poskytovatel nenese žádnou odpovědnost za jakoukoliv škodu způsobenou Uživateli v důsledku užití aplikace Moje Závěť.</p>
          <p><strong>Odstoupení od smlouvy</strong></p>
          <p>Objednávku lze zrušit a od smlouvy odstoupit pouze ve lhůtách a za podmínek uvedených v těchto podmínkách.</p>
          <p>Objednávku lze zrušit odesláním stornovacího emailu na podpora@goodbye.cz. Do emailu je uživatel povinen uvést číslo objednávky.&nbsp;</p>
          <p>Od smlouvy uzavřené mezi Poskytovatelem a Uživatelem může Uživatel odstoupit ve lhůtě 14 dnů ode dne uzavření smlouvy o poskytování služeb. Oznámení o odstoupení od smlouvy je třeba zaslat na email podpora@goodbye.cz. Přijetí oznámení od smlouvy Poskytovatel bez zbytečného odkladu emailem potvrdí.&nbsp; Odstoupením od smlouvy se smlouva od počátku ruší a hledí se na ni, jakoby nebyla uzavřena. V případě odstoupení od smlouvy Poskytovatel vrátí platbu, kterou od Uživatele na základě smlouvy přijal, a to do 14 dnů od odstoupení od smlouvy na účet, ze kterého byla platba odeslána Poskytovateli nebo na účet uvedený v odstoupení od smlouvy.&nbsp; V souladu s ust. § 1837 občanského zákoníku nelze od smlouvy odstoupit v případě, že služba byla splněna s výslovným souhlasem Uživatele před uplynutím lhůty pro odstoupení od smlouvy. Tento důsledek Uživatel bere na vědomí souhlasem s poskytnutím služby před uplynutím lhůty pro odstoupení od smlouvy. V takovém případě nelze přijatou platbu vrátit.&nbsp;</p>
          <p>Poskytovatel si vyhrazuje právo od smlouvy odstoupit v případě, že:</p>
          <ul>
          <li>službu není z objektivních příčin možné za původních podmínek poskytnout,</li>
          <li>nebyly předem poskytnuty veškeré důležité informace, potřebné k tvorbě závěti,</li>
          <li>plnění se stane objektivně nemožným či protiprávním,</li>
          <li>v případě podstatného porušení těchto obchodních podmínek Uživatelem</li>
          </ul>
          <p>V případě, že nastala některá z výše uvedených skutečností, společnost bude Uživatele o odstoupení Poskytovatele od smlouvy neprodleně informovat a to na e-mail, ze kterého byla odeslána objednávka. Odstoupení je vůči Uživateli účinné okamžikem, kdy je Uživateli doručeno. Poskytovatel vrátí přijatou platbu bezhotovostně na účet, ze kterého Uživatel úhradu provedl, a to do 5ti dnů od odstoupení od smlouvy.&nbsp;</p>
          <p><strong>Cena a platební podmínky&nbsp;</strong></p>
          <p>Cena za službu je cenou smluvní, je uvedena u každé nabízené služby. Uživatel odesláním objednávky potvrzuje souhlas s cenou. Cenu je možno hradit bezhotovostně platební kartou nebo jakýmkoliv jiným způsobem, který platební brána nabízí.</p>
          <p>Uživatel bere na vědomí, že uhrazení ceny je předpokladem k zahájení realizace služby.&nbsp;</p>
          <p><strong>Garance dostupnosti služby, poruchy a odstávky</strong></p>
          <p>Poskytovatel zajistí bezproblémový provoz služby s výjimkou doby nezbytné pro údržbu technických a softwarových prostředků, jejichž prostřednictvím je služba provozována.</p>
          <p>Poskytovatel je oprávněn provádět plánované odstávky systémů za účelem jejich údržby a aktualizací.</p>
          <p>Poskytovatel je oprávněn provádět neplánované odstávky, pokud to situace vyžaduje a je nutné je neodkladně provést za účelem zajištění dalšího chodu služeb a serverů.</p>
          <p>Poruchou se rozumí chyba v technických nebo softwarových prostředcích Poskytovatele, která způsobuje úplnou nefunkčnost či nedostupnost služeb, následkem čehož je Uživatel nemůže v plné míře využívat. Za poruchu se nepovažuje plánovaná odstávka.</p>
          <p>Poskytovatel neručí za nefunkčnost či nedostupnost služby, která je zapříčiněna třetí stranou.</p>
          <p>Poskytovatel nenese žádnou zodpovědnost za nefunkčnost či nedostupnost systému, která byla způsobena technickým či jiným problémem na straně subdodavatele, poruchou či odstávkou v síti Internet mezi Uživatelem a servery Poskytovatele či vyšší mocí. Poskytovatel nenese zodpovědnost za nedostupnost služby v případě nefunkčnosti připojení Uživatele k Internetu.</p>
          <p>O poruchách, odstávkách a důležitých změnách je Poskytovatel povinen informovat Uživatele na svých webových stránkách v rámci možností s dostatečným předstihem.</p>
          <p>V případě nefunkčnosti či poruchy služby je Uživatel povinen závadu písemně (e-mailem, kontaktním formulářem) reklamovat a to nejpozději do 24 hodin od počátku nefunkčnosti služby.</p>
          <p>Na případnou náhradu z důvodu poruchy služby nemá Uživatel nárok v případě, že z jeho strany nebyly řádně a včas uhrazeny všechny poplatky za veškeré poskytované služby Poskytovatelem, služba je vypovězena nebo je služba nefunkční kvůli předchozímu jednání Uživatele, nebo Uživatel písemně prokazatelným způsobem (e-mailem, kontaktním formulářem) neprovede reklamaci nefunkčnosti služby nejpozději do 24 hodin od počátku nefunkčnosti služby.</p>
          <p>Poskytovatel neručí vzhledem k povaze e-mailové komunikace za korektní doručování e-mailových zpráv ze svých serverů či na své servery a z/do své sítě a nenese odpovědnost za případné škody způsobené nedoručenou či ztracenou e-mailovou zprávou.</p>
          <p>Poskytovatel neodpovídá za ztrátu či poškození dat v důsledku hardwarové poruchy serveru či diskového pole (zejména porucha pevného disku).</p>
          <p><strong>Smlouva o zpracování osobních údajů</strong></p>
          <p>Užívání aplikace Moje Závěť zahrnuje aktivity, při kterých může docházet ke zpracování osobních údajů Zpracovatelem pro Uživatele, jakožto správce údajů, ve smyslu nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) („Nařízení“).</p>
          <p>Poskytovatel i Uživatel mají zájem na tom dostát všem povinnostem, které jim vyplývají z Nařízení.</p>
          <p>Na základě článku 28 Nařízení je Uživatel (jakožto správce) povinen uzavřít s Poskytovatelem (jakožto zpracovatelem) písemnou smlouvu o zpracování osobních údajů, ve které Poskytovatel mimo jiné poskytne dostatečné záruky o technickém a organizačním zabezpečení ochrany osobních údajů v rámci aplikace Moje Závěť.</p>
          <p>Poskytovatel bude ve smyslu článku "Smlouva o zpracování osobních údajů" bodu 2) Nařízení pro Uživatele zpracovávat osobních údaje, které Uživatel získal nebo získá v souvislosti se svou činností, nebo které pro Uživatele za tímto účelem získá samotný Poskytovatel („Osobní údaje“), a to v rámci plnění povinností Poskytovatele vyplývajících z JLS, LSU a/nebo Jednorázové licence.</p>
          <p>Účelem tohoto článku je stanovení rozsahu povinností Poskytovatele souvisejících především se zajištěním ochrany Osobních údajů při jejich zpracování.</p>
          <p>Poskytovatel bude dle této Smlouvy zpracovávat Osobní údaje klientů, jejich členů rodiny, poradců a osob v podobném postavení, stejně tak jako údaje pracovníků Uživatele a dalších osob, jejichž údaje Uživatel vloží do jakéhokoliv dokumentu v aplikaci Moje Závěť („Subjekty údajů“) v rozsahu:</p>
          <ol>
          <ul>
          <li>jméno a příjmení;</li>
          <li>datum narození;</li>
          <li>adresa trvalého pobytu a kontaktní adresa;</li>
          <li>další kontaktní údaje (telefonní číslo, e-mailová adresa);</li>
          <li>další údaje vložené do jakéhokoliv dokumentu v aplikaci Moje Závěť v rámci daného uživatelského účtu.;</li>
          </ul>
          </ol>
          <p>Uživatel a Poskytovatel tímto ujednávají, že jakékoliv údaje vložené Uživatelem do databáze obsažené v aplikaci Moje Závěť jsou části databáze shromážděné Poskytovatelem a pořizovatelem databáze je výhradně Poskytovatel. Uživateli nevzniká jakékoliv právo k databázi obsažené v aplikaci Moje Závěť.</p>
          <p>Osobní údaje Subjektů údajů bude Poskytovatel zpracovávat nejdéle do nenávratného smazání (nikoli pouze deaktivování) daného uživatelského účtu a/nebo do doby anonymizace daného dokumentu v aplikaci Moje Závěť, ke kterému se údaje vztahují; podle toho, která okolnost nastane dříve.</p>
          <p>Poskytovatel se zavazuje, že ve smyslu článku 32 Nařízení přijme s přihlédnutím ke stavu techniky, nákladům na provedení, povaze, rozsahu, kontextu a účelům zpracování i k různě pravděpodobným a různě závažným rizikům pro práva a svobody fyzických osob veškerá technická a organizační opatření k zabezpečení ochrany Osobních údajů způsobem uvedeným v Nařízení či jiných obecně závazných právních předpisech k omezení možnosti neoprávněného nebo nahodilého přístupu k Osobním údajům, k jejich změně, zničení či ztrátě, neoprávněným přenosům, k jejich jinému neoprávněnému zpracování, jakož i k jinému zneužití Osobních údajů.</p>
          <p>Tento článek VOP se řídí ve věcech zde neupravených zákonnými ustanoveními vztahujícími se ke smlouvě o zpracování osobních údajů.</p>
          <p><strong>Závěrečná ustanovení</strong></p>
          <p>Uživatel, který je spotřebitelem, výslovně žádá Poskytovatele, aby mu byl Dokument vygenerován co nejdříve, tedy aby Poskytovatel začal s plněním před uplynutím lhůty k odstoupení od smlouvy.</p>
          <p>Právní vztah mezi Poskytovatelem a Uživatelem se řídí právem státu Poskytovatele a případné spory budou rozhodovány věcně a místně příslušným soudem podle zapsaného sídla Poskytovatele.</p>
          <p>Tyto obchodní podmínky nabývají platnosti a účinnosti dne 1. 3. 2021.</p>
          <p>&nbsp;</p>
          <p>Goodbye s.r.o.</p>
          <p>Jiří Štěpánek, jednatel</p>
        </div>
      </OpZavetiInner>
    </Layout>
  );
}



export default ObchodniPodminkyZaveti;


